*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.ssi-image-container{
    position: relative;
    background: #333333;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}
.ssi-dots{
    position: absolute;
    z-index: 10; 
    /* border: 1px solid #ed6262; */
}
.ssi-song-image{
    width: 45px ;
    height: 45px ;
    /* border: 2px solid #ff3434; */
}
.ssi-icons img{
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.ssi-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    padding: 15px 0;
    gap: 10px;
    margin: 7px;   
    border-radius: 5px;
}