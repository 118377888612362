@import url(https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold);

.root_container{
    overflow: hidden;
}
.upperBox{
    display: flex;
    height: 65%;
    justify-content: space-between;
    padding: 0 10vw;
    /* border:2px solid black; */

}
#heading{
    
    /* text-align: center; */
    font-family: "Eina01-Bold";
    color: #fff;

}
.mid_container{
    margin: 10px 0;
    /* width: 50vw; */
    /* height: 70vh; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 5px;
    /* border: 2px solid black; */

}

/* .mid_container:nth-child(2){
    padding-left: 50px;
} */

.topVocls{
    width: 800px;
    height: 300px;
    background: #9a9a9a9f;
    border-radius: 10px;
    margin: 10px 0;
}
#btn2{
    display: none;
}


.right_image img{
    /* height: 12rem; */
    height: 70%;
    width: 100%;
}

.right_image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.info_container{
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    gap: 10px;
    flex-wrap: wrap;
    height: 100%;
    padding: 0 20px;
    /* border: 2px solid black; */
}

@media screen and (max-width: 1200px) {

    .left_image img, .right_image img{
        display: none;
    }
    .upperBox{
        width: 100%;
        height: 100vh;
    }
    .mid_container{
        width: 100%;
    }
    
    #btn1{
        display: none;
        
    }
    #btn2{
        display: inline;
    }
    #faq{
        width: 100%;
    }
    #faq_brack{
        display: none;
    }
    #heading{
        text-align: left;
        width: 80vw;
        font-size: 2rem;
        
    }

}