.say_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 300px;
    /* width: 50%; */
    /* min-width: 55rem; */
    max-width: 60rem;
    /* border: 2px solid black; */
    gap: 10px;
    /* border: 2px solid black; */
}

.say_container .image{
    width: 210px;
    height: 210px;
    /* border-radius: 50%; */
    /* border: 2px solid black; */
}


.image img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.say_container .containt{
    display: flex;

    width: 70%;
    flex-direction: column;
    gap: 10px;
}

.containt h1{
    /* font-weight: 500; */
    font-size: 2.3rem;
}


.containt .mail{
    font-size: 1.5rem;
}

.comment{
    color: rgb(240, 248, 255);
}

.com_vocal{
    display: flex;
    /* width: 100%; */
    padding: 5px 10px;
    border-radius: 5px;
    overflow: hidden;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.364);
}
.com_vocal .song_comment{
    width: 50%;

}

.com_vocal .vocal{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

}


@media screen and (max-width: 800px) {
    .say_container{
        /* justify-content: start; */
    }
    .containt{
        min-width: 100%;
    }
    .say_container{
        width: 90vw;
    }
    .song_comment{
        min-width: 100%;
    
    }
    .com_vocal{
        flex-wrap: wrap;
    }
    
    
}