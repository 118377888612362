@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf")
    format("truetype");
}

.wrapper {
  padding: 0 2rem 2rem 2rem;
  font-family: "Roboto Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: fixed;
  background-color: rgb(51, 51, 51);
  width: 42%; */
}
/* 
@media (max-width: 1050px) {
  .wrapper {
    width: 75% !important;
  }
}
@media (max-width: 599px) {
  .wrapper {
    top: 0.9rem !important;
  }
}
@media (max-width: 500px) {
  .wrapper {
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .wrapper {
    top: 0.4rem !important;
  }
} */

.top_bar {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  column-gap: 2rem;
}

.top_bar h4 {
  color: "white";
}

.top_bar .close_icon {
  color: gray;
  margin-top: 30px;
  cursor: pointer;
}

.wrapper .absolute_close_icon {
  display: block;
  color: gray;
  margin: 10px 10px 0px 0px;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  left: 100;
  right: 0;
  bottom: 100;
  top: 0;
}

.email,
.name {
  margin: 0;
  margin-top: -70px;
  font-size: 0.9rem;
}

.name {
  margin-top: 1.5rem !important;
}

.stats_wrapper {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  row-gap: 0.1rem;
  justify-content: center;
}

.stats_wrapper span {
  font-size: 1.3rem;
  color: white;
  vertical-align: bottom;
}

.stats_wrapper p {
  vertical-align: middle;
  font-size: 0.8rem;
  display: inline;
  padding-left: 1rem;
}

.button_group {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.btn,
.active_btn {
  width: 178px;
  margin: 1.5rem 20px 1.5rem 0;
  height: 47px;
  background: #282828;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.download_icon {
  margin-top: 8px;
}

.vocal_input_container {
  margin: 3rem 0 1.5rem 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  width: 100%;
  height: 47px;
  background: #282828;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.vocal_input_container form input {
  border: none;
  outline: none;
  color: white;
  width: 200%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  height: 40px;
}

@media (max-width: 1250px) and (min-width: 1050px) {
  .vocal_input_container form input {
    width: 150%;
  }
}

.vocal_input_container span {
  font-size: 1.5rem;
  margin-top: 7px;
}

.vocal_results {
  margin-top: 1.5rem;
  display: flex;
  column-gap: 1rem;
  width: 100%;
}

.valid_btn {
  align-self: center;
  background: #86db78;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 1000px;
  height: 41px;
  color: white;
}

.vocal_results p {
  display: inline;
  font-size: 0.8rem;
  align-self: center;
}

.vocal_results span {
  display: inline;
  color: #86db78;
  font-size: 1rem;
  align-self: center;
}

.save_btn {
  margin: 1.5rem 0;
  width: 100%;
  height: 55px;
  background: #86db78;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: white;
}
