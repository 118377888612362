.profile-menu-container{
    display: flex;
    flex-direction: column;
    gap: 15px;
    min-width: 20rem;
    padding-left: 0;
    margin-top: 1vh;
    margin-left: 80px;
}
@media screen and (max-width:1000px) {
    .profile-menu-container{
        margin-left: 20px;

    }
}


.image_info{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    background-color: #3C3C3C;
    border-radius: 5px;
    border:1px solid #ffffff17;
    /* border: 2px solid greenyellow; */
}

.image_info .profile-image{
    /* height: 55px;
    width: 235px; */
    width: 80%;
    height: 70%;
    overflow: hidden;
    object-fit: cover;
    /* width: 250px; */
    /* border: 2px solid burlywood; */
    border-radius: 5px;
    object-fit: cover;
}


.Menu-tabs{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
}

