.fr-container{
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-top: 10px;
    width: 60%;
    /* justify-content: start; */
    gap: 8px;
}

.fr-container .rf-referal{
    display: flex;
    align-items: center;
    gap: 5px;
}

.fr-container .rf-email-status{
    display: flex;
    justify-content: space-between;
    width: 60%;
}



.rf-steps-container{
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid #3B3B3B ;
}

.rf-earned-container .total-earning{
    display: flex;
    gap: 10px;
    align-items: center;
    border-top: 2px solid #3B3B3B ;
    border-bottom: 2px solid #3B3B3B ;
}





/* ReferalStep */
.referal-step-contianer{
    max-width: 250px;
    padding: 5px;
}
.referal-step-contianer .step-heading{
    display: flex;
    align-items: center;
    gap: 10px;
}

.step-heading .num-container{
    background: #000000;
    /* font-weight: bold; */
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px 0;
}
.referal-step-contianer .step-detail{
     color: #ffffffb1;
}


/* Referal Form */

.referal-form-container{
    /* background-color:#D9D9D9 ; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
    padding: 10px;
}

.referal-form-container .referal-form-label{
    color: #000000;

}

.referal-link{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding: 8px; */
    /* background-color: #fff; */
    /* color: #00000075; */
    /* padding: 10px 0; */
    padding-left: 5px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #9F9F9F;
    height: 50px;

}

.referal-link .link-btn{
    min-height: 100%;
    text-align: center;
    border: 1px solid #57A4FF !important;
    background-color: #57a4ff45;
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    cursor: pointer;


}


.refer-email{
    /* background: #fff; */
    
    height: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #9F9F9F;
    height: 50px;
    

}

.refer-email input{
    
    font-size: 1.25rem;
    border: none;
    background-color: transparent;
    width: 100%;
    color: #fff;
    margin-left: 10px;
}

.refer-email input:focus{
    outline: none;
}

.refer-email .share-btn{
    min-height: 100%;
    background: #636363;
    color: #fff;
    font-size: 1rem;
    border: none;
    font-size: 1rem;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    padding: 0 15px;
    border: 1px solid #9F9F9F !important;
}

@media screen and (max-width:1000px) {
    .fr-container{
        /* background: #000; */
        min-width: 100%;
        margin-top: 20px;
        padding: 0 10px;
    }
    
}