
.info_card_container{
    width: 21%;
    min-height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    border-radius: 10px;
    background: #0000003b;
    /* gap: 5px; */
    margin-bottom: 10px;
    padding:0 30px;
    
    /* border: 2px solid black; */
}

.info_card_container p{
    text-align: center;
}

