.card-prompt-container{
    /* background-color: #B4B4B4; */
    position:absolute;
    width: calc(100vw - 70px) ;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    

}

.card-prompt-stickey{
    background-color: #0000009e;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.card-prompt-container .card-prompt{
    /* min-width: 500px; */
    position: sticky;
    min-width: 30vw;
    min-height: 100px;
    background-color:#333333;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 20px;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
}

.card-prompt-cross{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.card-prompt .card-prompt-card{
    width: 120px;
}

.card-prompt-btn-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.card-prompt-btn-container .card-prompt-btn{
    padding: 10px;
    background-color: #3C3C3C;
    border: 1px solid #333333;
    padding: 10px 40px;
    border-radius: 5px;
    cursor: pointer;
}

/* Alart */

.btn-alart-prompt{
    position: sticky;
    min-width: 30vw;
    min-height: 100px;
    background-color:#f0eded;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    gap: 20px;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
}


.alart-btn-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.alart-btn-container .alart-btn{
    padding: 10px;
    background-color: #D9D9D9;
    border: 1px solid #ECECEC;
    padding: 10px 40px;
    border-radius: 5px;
    cursor: pointer;

}

.alart-btn-container #alart-btn-2{
    color:#FF1B1B
}

.alart-btn-container #alart-btn-2:hover{
    background-color: #FF1B1B;
    color: #000;
}


.btn-alart-prompt .alart-info-container{
    display: flex;
    flex-direction: column;
    color: #000;
    text-align: center;
    gap: 10px;
}







/* index */
.form-container form{
    padding: 50px;
    display: flex;
    max-width: 35rem;
    flex-direction: column;
    gap: 10px;
    border-right: 2px solid #3B3B3B;
    
    
}

.button-container{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 2.5rem;
    gap: 10px;
}

.button-container button{

    padding: 10px 20px;
    border-radius: 5px;    
    border: 1px solid #333333; 
    cursor: pointer;
}

.submit-btn{
    background-color: #2B5A90;
    color: #FEFEFE;
    margin: 10px;
}

.cancele-btn{
    background-color: #3C3C3C;   
    color: #B4B4B4;
}

.input-container {
    min-width:30rem;
    min-height: 35px;
    border-radius: 5px;
    background-color: #3C3C3C;
    border: 2px solid #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

}

.input-container input{
    background-color: transparent;
    font-size: 1rem;
    color: aliceblue;
    border: 0px;
    width: 70%;
    text-align: right;
    color: #B4B4B4;
}

.form-container .input-container input:focus{
   outline: 10rem;

}

.input-container .input-div{
    
    color: #B4B4B4;
}

.delete-btn-container{
    border-top: 2px solid #3B3B3B;
}

.delete-btn{
    color: #FF5757;
    background-color: #3C3C3C;   
    cursor: pointer;
    margin-top: 20px;
}

.delete-btn:hover{
    color: #3C3C3C;
    background-color:#FF5757 ;   
    
}

@media screen and (max-width: 1000px) {
    .profile-main-container{
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        /* border: 2px solid red; */
            }
    .right-side-container{
        flex-direction: column;
    }
    .form-container{
        width: 100%;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }
    .form-container form{
        border-right: none;
        max-width: 100%;
        
        
    }
    .profile-Menu-main-container{
        width: 90%;
    }

    .input-container{
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        
    }
    .subscription-Info-main-container{
        width: 100%;
    }
    
    .card-prompt-container{
        width: 100vw;
        height: 100%;
    }

}
