@import url(https://db.onlinewebfonts.com/c/1868e326d3ee28e5395f6efa2bc037bf?family=Eina01-Regular);
@import url(https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold);
@import url(https://db.onlinewebfonts.com/c/b158925a1d762313849f2482a415369d?family=Eina03-SemiBold);

.Select-plan{
    background-color: #333333;
    /* min-height: 80vh; */
    /* min-width: 80vw; */
    color: white;
    font-family: "Eina01";
    
    
}
.sp-header{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background: #252525;
    padding: 20px 0;
    text-transform: capitalize;
}

.sp-heading{
    font-size: 4rem;
    font-weight: 400;
    font-family: "Eina03-SemiBold";
    /* font-family: "Eina01-Bold"; */
    padding: 20px 10%;
    background: linear-gradient(91.97deg, #5F5F5F 9.8%, #57A4FF 133.46%);

}
.sp-info{
    padding-left: 80px;
    
}

.sp-info-heading{
    /* font-family: "Eina01"; */
    font-size: 2rem;
    font-weight: 600;
    padding: 20px 0;
}

.sp-info-sideinfo{
    display: flex;
    align-items: center;
    max-width: fit-content;
    font-size: 1rem;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    margin-top: 25px;
    
}

.sp-info button{
    padding: 10px 80px;
    font-size:20px;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    background: #57A4FF;
    margin: 20px 0;
    cursor: pointer;
    
    
}

.sp-question{
    font-size: 15px;
    color: #ffffffd9;
   
    display: flex;
    align-items: center;
    gap: 3px;
    
    padding-bottom: 45px;
    cursor: pointer;
    
}
.sp-question img{
    
    width: 18px;
    height: 18px;
}

.sp-card{
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.sp-info-side{
    width: 200px;
    font-family: "Eina01";
}

@media screen and (max-width: 1000px) {
    .sp-info{
        padding-left: 20px;
        
    }
    
}
