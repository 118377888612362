@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf")
    format("truetype");
}

.wrapper {
  padding: 0 2rem 2rem 2rem;
  font-family: "Roboto Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: fixed;
  background-color: rgb(51, 51, 51);
  width: 42%; */
}

.top_bar,
.vocal_top_bar {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  column-gap: 2rem;
}

.top_bar h4 {
  color: "white";
}

.top_bar .close_icon {
  color: gray;
  margin-top: 30px;
  cursor: pointer;
}

.email,
.name {
  margin: 0;
  font-size: 0.9rem;
}

.name {
  margin-top: 1.5rem !important;
}

.form_heading {
  margin: 1.5rem 0 0 0;
  font-size: 0.95rem;
  width: 95%;
  padding: 0.5rem 1rem;
  border: 1px solid white;
}

.label {
  display: block;
  margin-top: 1.5rem !important;
  font-size: 0.8rem;
}

.text_area {
  margin-top: 10px;
  display: block;
  height: 70px;
  width: 85%;
  background: #ebebeb;
  border-radius: 2px;
  color: black;
  outline: none;
  padding: 0 10px;
  font-size: 0.8rem;
  font-weight: lighter;
}

.button_group {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.btn,
.active_btn {
  width: 178px;
  margin: 1.5rem 20px 1.5rem 0;
  height: 47px;
  background: #282828;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.active_btn {
  background-color: rgba(134, 219, 120, 1);
  border: none;
}

.vocals_heading {
  display: block;
  font-size: 1.2rem;
  margin: 0 !important;
}

.vocal_content .vocal {
  width: 200px;
  color: white;
}

.vocal_content {
  margin: 1rem 0 0rem 0;
  display: flex;
  justify-content: space-around;
  color: white;
}

.vocal_content p {
  margin-top: 1rem;
  font-size: 0.7rem;
}

.download_icon {
  margin-top: 18px;
}
