.affiliate-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 30px;
    max-width: 50rem;
}

.affiliate-container .affiliate-heading{
    
}

.affiliate-btn .start-btn{
    background-color: #38BC22;
    color: #fff;
    border: 1px solid #333333;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    

}




.affiliate-container .affiliate-steps{
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #3B3B3B;
    padding-top: 20px;
}

.affiliate-steps .affiliate-arrow{
    display: flex;
    align-items: center;
}












/* affiliate step */

.affiliate-step-container{
    /* background: #000; */
    max-width: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid white; */


}

.affiliate-step-container .affiliate-image{
    background-color: #fff;
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.affiliate-step-container .step-heading{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    /* color: aquamarine; */
}

.affiliate-step-container .step-detail{
    opacity: 0.7;
    text-align: center;
}