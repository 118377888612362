
.subs-info-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    /* border-left:1px solid #3B3B3B; */
}

.subs-plan-container{
    display: flex;
    justify-content: space-between;

}

.heading-container{
    display: flex;
    flex-direction: column;
}

.subs-cards-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.subs-btn-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subs-btn-container .change-btn{
    color: #57A4FF;
}
.subs-btn-container .end-btn{
    color: #B4B4B4;
}
.subs-btn-container .subs-btn{
    padding: 10px;
    border-radius: 5px;
    background-color: #3C3C3C;
    border: 1px solid #333333;
    cursor: pointer;
}


/* infoCard CSS */

.info-card-container{
    min-width:10rem;
    padding: 10px;
    border: 2px solid #3B3B3B;
    border-radius: 10px;
}

.subs-plan-tital{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.subs-plan-card-info{
    display: flex;
    justify-content: space-between;
   
}



/* plane Card css */

.plan-card-continer{
    background-color: #3B3B3B;
    min-width: 100px;
    padding: 10px;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    border-radius: 5px;
    cursor: pointer;
}
.coins-container{

}
.plan-card-heading{

}
.plan-card-price{

}