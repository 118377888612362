@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf")
    format("truetype");
}

.wrapper {
  padding: 0 2rem 2rem 2rem;
  font-family: "Roboto Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: fixed;
  background-color: rgb(51, 51, 51);
  width: 42%; */
}

.top_bar,
.vocal_top_bar {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  column-gap: 2rem;
}

.top_bar h4 {
  color: "white";
}

.top_bar .close_icon {
  color: gray;
  margin-top: 30px;
  cursor: pointer;
}

.email {
  margin: 0 0 1.5rem 0;
  font-size: 0.9rem;
}

.pay_status_icon {
  color: #86db78 !important;
  font-size: 1.5rem;
  margin: 1rem 1rem 0 0;
  vertical-align: middle;
}
.pay_status,
.pay_date {
  margin: 1.5rem 1.5rem 0 0 !important;
  font-size: 0.9rem;
  display: inline;
}
