.tab-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 5px;
    background-color:#3C3C3C ;
    border:1px solid #ffffff17;
    padding: 5px 10px;   
    cursor: pointer;                               
}

#tab5:hover{
    background: #ffffff;
}


.tab-container:hover{
    background-color: #2a8dff;
}

.active-tab{
    background-color: #2a8dff;
}