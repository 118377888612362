.dialogueRow{
    background-color: #3D4756;
}

.dialogueRow:hover{
    background-color: #57A4FF;
}

.dialogueRowBackground{
    
    background-color: #3D4756;
}