@import url(https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold);
.card{
    
    
    
    /* min-height: 10rem;
    min-width: 20rem;
     */
    border-radius: 5px;
    padding: 10px;
   
    
    
    
    font-family: "Eina03-SemiBold";
    position: relative;
    transition: transform ease 0.5s;
    color: #fff;
    background: #212121;
    /* font-family: "Eina01-Bold"; */
}
.card-batch{
    position: absolute;
    right: 10%;
    top: -5%;
    background: #57A4FF;
    border-radius: 5px;
    padding: 5px;
    color: #000;
}
.card:hover{
    

    /* border: 2px solid rgb(0, 229, 255); */
    /* filter: drop-shadow(0px 10px 10px rgb(156, 245, 253)); */
    
    
}
.selected {
    transform: scale(1.1);
    filter: drop-shadow(0px 10px 10px #57a3ffaf);
    border: 5px solid #57A4FF;
  }

.plan{
    display: flex;
    justify-content: space-between;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px; */
    /* height: 30%; */
    align-items: center;
    /* padding: 0 10px; */
    
}

.plan-name{
    font-size: 1.5rem;
   
}

.plan img{
    /* width: 20px;
    height: 20px; */
}

.info{
    display: flex;
    margin: 20px 0;
    /* height: 70%; */
    
    
}

.num img {
    /* height: 30px;
    width: 30px; */
}



.value{
    font-size: 3rem;
}

#price-value{
    /* font-family: "Eina01"; */

}


.coins{
    /* width: 50%; */
    min-width: 150px;
    margin: 5px 0;
    padding: 0 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #424242;
    
    
}
.price{
    /* width: 50%; */
    margin: 5px 0;
    padding: 0 10px;
    min-width: 150px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    border-left: 1px solid #424242;

}





.num{
    display: flex;
    height: calc(100% - 20px);
    justify-content: center;
    align-items: center;
    /* gap: 5px; */

}

.coins .num{
    gap: 5px;
}

.sp-choose-btn{
    width: 100%;
    height: 50px;
    border-radius: 5px;
    
    color: #000;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width:1000px) {
    
    
}

