@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Mono&display=swap);
@import url(https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold);
@import url(https://db.onlinewebfonts.com/c/1868e326d3ee28e5395f6efa2bc037bf?family=Eina01-Regular);
@import url(https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold);
@import url(https://db.onlinewebfonts.com/c/b158925a1d762313849f2482a415369d?family=Eina03-SemiBold);
@import url(https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold);
/* @import url(https://db.onlinewebfonts.com/c/032d6b2c34344e22d2cbca6b7050d642?family=Eina01-SemiBold); */
/* @import url("https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold"); */

@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf") format("truetype");
}




body {
  margin: 0;
  font-family: "Eina01-SemiBold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#fff";
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

p,
h1,
h2,
h2,
h4,
h5,
h6 {
  font-family: "Eina01", "Roboto Mono", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #42de29;
  border-radius: 6px;
}

.error {
  color: #ff0000;
}

.m-0 {
  margin: 0px;
}

.pointer {
  cursor: pointer;
}

p {
  margin: 0px;
}

.apexcharts-toolbar {
  display: none !important;
}

.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 1 !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 0% !important;
}

.MuiTimelineDot-defaultGrey {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid lightgray;
}

.MuiTimelineContent-root {
  margin-top: 10px !important;
}

.MuiToolbar-regular {
  min-height: 64px !important;
}

wave {
  overflow: hidden !important;
}

header {
  z-index: 2000 !important;
}

.MuiDrawer-paper {
  background-color: #333333 !important;
}

.truncate {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
}

.scroll-left {
  width: 100%;
  position: relative;
}
.scroll-left p {
  width: 100%;
  height: 100%;
  margin: 0;
  margin-top: 5px;
  text-align: center;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -webkit-animation: scroll-left 10s linear infinite;
  animation: scroll-left 10s linear infinite;
}
/* Move it (define the animation) */
@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes scroll-left {
  0% { /* Browser bug fix */
    -webkit-transform: translateX(100%); /* Browser bug fix */
    transform: translateX(100%);
  }
  100% { /* Browser bug fix */
    -webkit-transform: translateX(-100%); /* Browser bug fix */
    transform: translateX(-100%);
  }
}

.composition {
  position: relative;
  width: 40%;
  top: -60%;
  margin-left: 200px;
}

@media (max-width: 1250px) {
  .composition {
    display: none;
  }
}

.composition__photo {
  width: 40%;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  outline-offset: 2rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo {
    float: left;
    position: relative;
    width: 33.33333333%;
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  }
}
.composition__photo--p1 {
  left: 25%;
  top: 9rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p1 {
    top: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.composition__photo--p2 {
  right: 0;
  top: 2rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -1rem;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 100;
  }
}
.composition__photo--p3 {
  left: 0;
  top: 14rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p3 {
    top: 1rem;
    left: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.composition__photo:hover {
  outline: 1.5rem solid rgba(79, 141, 214, 0.6);
  -webkit-transform: scale(1.05) translateY(-0.5rem);
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.composition:hover .composition__photo:not(:hover) {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

option {
  background-color: #202020 !important;
}
#popover .MuiPaper-root {
  border: 2px solid red;
  left: 1051px !important;
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

.bpm-wrapper {
  position: relative;
}

.bpm-button,
.apply-button {
  background-color: transparent;
  width: 6rem;
  padding: 8px 2rem;
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
  border-radius: 5px;
  outline: none;
  border: 1px solid white;
  cursor: pointer;
}

.bpm-tooltip {
  display: none;
  background-color: rgb(77, 77, 77);
  position: absolute;
  left: -100px;
  padding: 20px 40px 20px;
  margin: 10px auto;
  width: 300px;
  z-index: 10000;
  border-radius: 6px;
}
.bpm-tooltip h6 {
  margin: 0 0 10px;
  color: white;
  text-align: center;
  font-size: 18px;
}

.active {
  display: unset !important;
}

.apply-button {
  border: 1px solid white;
  padding: 0.6rem 1rem;
  color: white;
  margin: 10px auto;
  float: right;
  cursor: pointer;
  outline: none;
  font-size: 15px;
}

.MuiSlider-root {
  width: 72% !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}

.scrollbar-container {
  max-width: 90vw;
  overflow: hidden;
  margin: 0 auto;
}

.table-row td{
  position: relative;
  z-index: 10;
}

.hr-text-container {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.hr-text {
  flex: 1 1;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0 5px;
}

.hr-text-span {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 20px;
}

.inputEmailCheckout > input[name=email] {
  text-align: right;
}

@media only screen and (min-width: 1280px) {
  .table-row:hover::before{
    content: '';
    border-radius: 10px;
    position:absolute;
    inset: 0;
    background-color: #464646;
    z-index: 1;
  }
  #table-play-row::before{
    content: '';
    border-radius: 10px;
    position:absolute;
    inset: 0;
    background-color: #57A4FF66;
    z-index: 1;
  }
}

@media only screen and (max-width: 1280px) {
  .table-row:hover{
    background-color: #464646;
  }
}

* {
    padding: 0;
}

body { background-color: #292929; }

section {
    margin-top: -80px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

ul#downArrow {
    position: relative;
    width: 40px;
    display: flex;
    align-items: center;
    flex-flow: column;
}

ul#downArrow li {
    list-style: none;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 100%;
    margin-bottom: 10px;
    -webkit-animation: ani 0.8s infinite ease-in-out;
            animation: ani 0.8s infinite ease-in-out;
    -webkit-animation-delay: calc(0.2s * var(--i));
            animation-delay: calc(0.2s * var(--i));
}

ul#downArrow::before,
ul#downArrow::after {
    position: absolute;
    content: '';
    background-color: #fff;
    width: 30px;
    height: 3px;
    bottom: 0;
}

ul#downArrow::before {
    right: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: center right;
            transform-origin: center right;
}

ul#downArrow::after {
    left: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: center left;
            transform-origin: center left;
}

@-webkit-keyframes ani {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5) translateY(-5px);
                transform: scale(0.5) translateY(-5px);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(20px);
                transform: scale(1) translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(0.5) translateY(40px);
                transform: scale(0.5) translateY(40px);
    }

}

@keyframes ani {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.5) translateY(-5px);
                transform: scale(0.5) translateY(-5px);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(20px);
                transform: scale(1) translateY(20px);
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(0.5) translateY(40px);
                transform: scale(0.5) translateY(40px);
    }

}



.root_container{
    overflow: hidden;
}
.upperBox{
    display: flex;
    height: 65%;
    justify-content: space-between;
    padding: 0 10vw;
    /* border:2px solid black; */

}
#heading{
    
    /* text-align: center; */
    font-family: "Eina01-Bold";
    color: #fff;

}
.mid_container{
    margin: 10px 0;
    /* width: 50vw; */
    /* height: 70vh; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    grid-gap: 5px;
    gap: 5px;
    /* border: 2px solid black; */

}

/* .mid_container:nth-child(2){
    padding-left: 50px;
} */

.topVocls{
    width: 800px;
    height: 300px;
    background: #9a9a9a9f;
    border-radius: 10px;
    margin: 10px 0;
}
#btn2{
    display: none;
}


.right_image img{
    /* height: 12rem; */
    height: 70%;
    width: 100%;
}

.right_image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.info_container{
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    grid-gap: 10px;
    gap: 10px;
    flex-wrap: wrap;
    height: 100%;
    padding: 0 20px;
    /* border: 2px solid black; */
}

@media screen and (max-width: 1200px) {

    .left_image img, .right_image img{
        display: none;
    }
    .upperBox{
        width: 100%;
        height: 100vh;
    }
    .mid_container{
        width: 100%;
    }
    
    #btn1{
        display: none;
        
    }
    #btn2{
        display: inline;
    }
    #faq{
        width: 100%;
    }
    #faq_brack{
        display: none;
    }
    #heading{
        text-align: left;
        width: 80vw;
        font-size: 2rem;
        
    }

}

.info_card_container{
    width: 21%;
    min-height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    border-radius: 10px;
    background: #0000003b;
    /* gap: 5px; */
    margin-bottom: 10px;
    padding:0 30px;
    
    /* border: 2px solid black; */
}

.info_card_container p{
    text-align: center;
}


.say_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 300px;
    /* width: 50%; */
    /* min-width: 55rem; */
    max-width: 60rem;
    /* border: 2px solid black; */
    grid-gap: 10px;
    gap: 10px;
    /* border: 2px solid black; */
}

.say_container .image{
    width: 210px;
    height: 210px;
    /* border-radius: 50%; */
    /* border: 2px solid black; */
}


.image img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.say_container .containt{
    display: flex;

    width: 70%;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.containt h1{
    /* font-weight: 500; */
    font-size: 2.3rem;
}


.containt .mail{
    font-size: 1.5rem;
}

.comment{
    color: rgb(240, 248, 255);
}

.com_vocal{
    display: flex;
    /* width: 100%; */
    padding: 5px 10px;
    border-radius: 5px;
    overflow: hidden;
    grid-gap: 10px;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.364);
}
.com_vocal .song_comment{
    width: 50%;

}

.com_vocal .vocal{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;

}


@media screen and (max-width: 800px) {
    .say_container{
        /* justify-content: start; */
    }
    .containt{
        min-width: 100%;
    }
    .say_container{
        width: 90vw;
    }
    .song_comment{
        min-width: 100%;
    
    }
    .com_vocal{
        flex-wrap: wrap;
    }
    
    
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
.ssi-image-container{
    position: relative;
    background: #333333;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
}
.ssi-dots{
    position: absolute;
    z-index: 10; 
    /* border: 1px solid #ed6262; */
}
.ssi-song-image{
    width: 45px ;
    height: 45px ;
    /* border: 2px solid #ff3434; */
}
.ssi-icons img{
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.ssi-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    padding: 15px 0;
    grid-gap: 10px;
    gap: 10px;
    margin: 7px;   
    border-radius: 5px;
}
.Select-plan{
    background-color: #333333;
    /* min-height: 80vh; */
    /* min-width: 80vw; */
    color: white;
    font-family: "Eina01";
    
    
}
.sp-header{
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    background: #252525;
    padding: 20px 0;
    text-transform: capitalize;
}

.sp-heading{
    font-size: 4rem;
    font-weight: 400;
    font-family: "Eina03-SemiBold";
    /* font-family: "Eina01-Bold"; */
    padding: 20px 10%;
    background: linear-gradient(91.97deg, #5F5F5F 9.8%, #57A4FF 133.46%);

}
.sp-info{
    padding-left: 80px;
    
}

.sp-info-heading{
    /* font-family: "Eina01"; */
    font-size: 2rem;
    font-weight: 600;
    padding: 20px 0;
}

.sp-info-sideinfo{
    display: flex;
    align-items: center;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    font-size: 1rem;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    margin-top: 25px;
    
}

.sp-info button{
    padding: 10px 80px;
    font-size:20px;
    font-weight: 600;
    border-radius: 5px;
    color: #fff;
    background: #57A4FF;
    margin: 20px 0;
    cursor: pointer;
    
    
}

.sp-question{
    font-size: 15px;
    color: #ffffffd9;
   
    display: flex;
    align-items: center;
    grid-gap: 3px;
    gap: 3px;
    
    padding-bottom: 45px;
    cursor: pointer;
    
}
.sp-question img{
    
    width: 18px;
    height: 18px;
}

.sp-card{
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
}

.sp-info-side{
    width: 200px;
    font-family: "Eina01";
}

@media screen and (max-width: 1000px) {
    .sp-info{
        padding-left: 20px;
        
    }
    
}

.card{
    
    
    
    /* min-height: 10rem;
    min-width: 20rem;
     */
    border-radius: 5px;
    padding: 10px;
   
    
    
    
    font-family: "Eina03-SemiBold";
    position: relative;
    transition: -webkit-transform ease 0.5s;
    transition: transform ease 0.5s;
    transition: transform ease 0.5s, -webkit-transform ease 0.5s;
    color: #fff;
    background: #212121;
    /* font-family: "Eina01-Bold"; */
}
.card-batch{
    position: absolute;
    right: 10%;
    top: -5%;
    background: #57A4FF;
    border-radius: 5px;
    padding: 5px;
    color: #000;
}
.card:hover{
    

    /* border: 2px solid rgb(0, 229, 255); */
    /* filter: drop-shadow(0px 10px 10px rgb(156, 245, 253)); */
    
    
}
.selected {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: drop-shadow(0px 10px 10px #57a3ffaf);
            filter: drop-shadow(0px 10px 10px #57a3ffaf);
    border: 5px solid #57A4FF;
  }

.plan{
    display: flex;
    justify-content: space-between;
    /* border-top-right-radius: 5px;
    border-top-left-radius: 5px; */
    /* height: 30%; */
    align-items: center;
    /* padding: 0 10px; */
    
}

.plan-name{
    font-size: 1.5rem;
   
}

.plan img{
    /* width: 20px;
    height: 20px; */
}

.info{
    display: flex;
    margin: 20px 0;
    /* height: 70%; */
    
    
}

.num img {
    /* height: 30px;
    width: 30px; */
}



.value{
    font-size: 3rem;
}

#price-value{
    /* font-family: "Eina01"; */

}


.coins{
    /* width: 50%; */
    min-width: 150px;
    margin: 5px 0;
    padding: 0 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #424242;
    
    
}
.price{
    /* width: 50%; */
    margin: 5px 0;
    padding: 0 10px;
    min-width: 150px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    border-left: 1px solid #424242;

}





.num{
    display: flex;
    height: calc(100% - 20px);
    justify-content: center;
    align-items: center;
    /* gap: 5px; */

}

.coins .num{
    grid-gap: 5px;
    gap: 5px;
}

.sp-choose-btn{
    width: 100%;
    height: 50px;
    border-radius: 5px;
    
    color: #000;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width:1000px) {
    
    
}


.tab-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-radius: 5px;
    background-color:#3C3C3C ;
    border:1px solid #ffffff17;
    padding: 5px 10px;   
    cursor: pointer;                               
}

#tab5:hover{
    background: #ffffff;
}


.tab-container:hover{
    background-color: #2a8dff;
}

.active-tab{
    background-color: #2a8dff;
}
.profile-menu-container{
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    min-width: 20rem;
    padding-left: 0;
    margin-top: 1vh;
    margin-left: 80px;
}
@media screen and (max-width:1000px) {
    .profile-menu-container{
        margin-left: 20px;

    }
}


.image_info{
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    padding: 10px;
    background-color: #3C3C3C;
    border-radius: 5px;
    border:1px solid #ffffff17;
    /* border: 2px solid greenyellow; */
}

.image_info .profile-image{
    /* height: 55px;
    width: 235px; */
    width: 80%;
    height: 70%;
    overflow: hidden;
    object-fit: cover;
    /* width: 250px; */
    /* border: 2px solid burlywood; */
    border-radius: 5px;
    object-fit: cover;
}


.Menu-tabs{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px 0;
}


.card-prompt-container{
    /* background-color: #B4B4B4; */
    position:absolute;
    width: calc(100vw - 70px) ;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    

}

.card-prompt-stickey{
    background-color: #0000009e;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.card-prompt-container .card-prompt{
    /* min-width: 500px; */
    position: -webkit-sticky;
    position: sticky;
    min-width: 30vw;
    min-height: 100px;
    background-color:#333333;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
}

.card-prompt-cross{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.card-prompt .card-prompt-card{
    width: 120px;
}

.card-prompt-btn-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.card-prompt-btn-container .card-prompt-btn{
    padding: 10px;
    background-color: #3C3C3C;
    border: 1px solid #333333;
    padding: 10px 40px;
    border-radius: 5px;
    cursor: pointer;
}

/* Alart */

.btn-alart-prompt{
    position: -webkit-sticky;
    position: sticky;
    min-width: 30vw;
    min-height: 100px;
    background-color:#f0eded;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    grid-gap: 20px;
    gap: 20px;
    padding: 10px;
    align-items: center;
    border-radius: 5px;
}


.alart-btn-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.alart-btn-container .alart-btn{
    padding: 10px;
    background-color: #D9D9D9;
    border: 1px solid #ECECEC;
    padding: 10px 40px;
    border-radius: 5px;
    cursor: pointer;

}

.alart-btn-container #alart-btn-2{
    color:#FF1B1B
}

.alart-btn-container #alart-btn-2:hover{
    background-color: #FF1B1B;
    color: #000;
}


.btn-alart-prompt .alart-info-container{
    display: flex;
    flex-direction: column;
    color: #000;
    text-align: center;
    grid-gap: 10px;
    gap: 10px;
}







/* index */
.form-container form{
    padding: 50px;
    display: flex;
    max-width: 35rem;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    border-right: 2px solid #3B3B3B;
    
    
}

.button-container{
    display: flex;
    justify-content: end;
    align-items: center;
    height: 2.5rem;
    grid-gap: 10px;
    gap: 10px;
}

.button-container button{

    padding: 10px 20px;
    border-radius: 5px;    
    border: 1px solid #333333; 
    cursor: pointer;
}

.submit-btn{
    background-color: #2B5A90;
    color: #FEFEFE;
    margin: 10px;
}

.cancele-btn{
    background-color: #3C3C3C;   
    color: #B4B4B4;
}

.input-container {
    min-width:30rem;
    min-height: 35px;
    border-radius: 5px;
    background-color: #3C3C3C;
    border: 2px solid #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;

}

.input-container input{
    background-color: transparent;
    font-size: 1rem;
    color: aliceblue;
    border: 0px;
    width: 70%;
    text-align: right;
    color: #B4B4B4;
}

.form-container .input-container input:focus{
   outline: 10rem;

}

.input-container .input-div{
    
    color: #B4B4B4;
}

.delete-btn-container{
    border-top: 2px solid #3B3B3B;
}

.delete-btn{
    color: #FF5757;
    background-color: #3C3C3C;   
    cursor: pointer;
    margin-top: 20px;
}

.delete-btn:hover{
    color: #3C3C3C;
    background-color:#FF5757 ;   
    
}

@media screen and (max-width: 1000px) {
    .profile-main-container{
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        /* border: 2px solid red; */
            }
    .right-side-container{
        flex-direction: column;
    }
    .form-container{
        width: 100%;
        /* display: flex;
        justify-content: center;
        align-items: center; */
    }
    .form-container form{
        border-right: none;
        max-width: 100%;
        
        
    }
    .profile-Menu-main-container{
        width: 90%;
    }

    .input-container{
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        
    }
    .subscription-Info-main-container{
        width: 100%;
    }
    
    .card-prompt-container{
        width: 100vw;
        height: 100%;
    }

}


.subs-info-container{
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
    /* border-left:1px solid #3B3B3B; */
}

.subs-plan-container{
    display: flex;
    justify-content: space-between;

}

.heading-container{
    display: flex;
    flex-direction: column;
}

.subs-cards-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;
    gap: 10px;
}

.subs-btn-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.subs-btn-container .change-btn{
    color: #57A4FF;
}
.subs-btn-container .end-btn{
    color: #B4B4B4;
}
.subs-btn-container .subs-btn{
    padding: 10px;
    border-radius: 5px;
    background-color: #3C3C3C;
    border: 1px solid #333333;
    cursor: pointer;
}


/* infoCard CSS */

.info-card-container{
    min-width:10rem;
    padding: 10px;
    border: 2px solid #3B3B3B;
    border-radius: 10px;
}

.subs-plan-tital{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.subs-plan-card-info{
    display: flex;
    justify-content: space-between;
   
}



/* plane Card css */

.plan-card-continer{
    background-color: #3B3B3B;
    min-width: 100px;
    padding: 10px;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    border-radius: 5px;
    cursor: pointer;
}
.coins-container{

}
.plan-card-heading{

}
.plan-card-price{

}
.layout {
    width: 100%;
  
    display: flex;
    grid-gap: 16px;
    gap: 16px;
  }
  
  .grow1 { flex-grow: 1; }
.pagination-wrapper {
  width: 80%;
  margin: 2rem auto;
}

.pagination-list,
.pagination {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  text-decoration: none;
  margin: 0;
}
.pagination-list li a,
.pagination li a {
  color: white;
  text-decoration: none !important;
  font-size: 12px;
}
.active-page {
  background-color: blue;
  color: white !important;
  padding: 0px 5px;
}

.first-class a,
.disabled a .last-class a,
.next-class a,
.prev-class a,
.last-class a {
  padding: 10px 10px;
  color: black !important;

  background-color: white;
  font-weight: medium;
}

@media (max-width: 650px) {
  .pagination-wrapper {
    width: 100%;
  }

  .next-class a,
  .prev-class a {
    display: none;
  }
}



@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf")
    format("truetype");
}

.sidebar_wrapper__16ow5 {
  padding: 0 2rem 2rem 2rem;
  font-family: "Roboto Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: fixed;
  background-color: rgb(51, 51, 51);
  width: 42%; */
}

.sidebar_top_bar__25p-f,
.sidebar_vocal_top_bar__2i-9P {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

.sidebar_top_bar__25p-f h4 {
  color: "white";
}

.sidebar_top_bar__25p-f .sidebar_close_icon__302xQ {
  color: gray;
  margin-top: 30px;
  cursor: pointer;
}

.sidebar_email__1UQIN,
.sidebar_name__38B5- {
  margin: 0;
  font-size: 0.9rem;
}

.sidebar_name__38B5- {
  margin-top: 1.5rem !important;
}

.sidebar_form_heading__37nNy {
  margin: 1.5rem 0 0 0;
  font-size: 0.95rem;
  width: 95%;
  padding: 0.5rem 1rem;
  border: 1px solid white;
}

.sidebar_label__35Mdk {
  display: block;
  margin-top: 1.5rem !important;
  font-size: 0.8rem;
}

.sidebar_text_area__1VQZM {
  margin-top: 10px;
  display: block;
  height: 70px;
  width: 85%;
  background: #ebebeb;
  border-radius: 2px;
  color: black;
  outline: none;
  padding: 0 10px;
  font-size: 0.8rem;
  font-weight: lighter;
}

.sidebar_button_group__2Iqz2 {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.sidebar_btn__LfYM2,
.sidebar_active_btn__1gOVS {
  width: 178px;
  margin: 1.5rem 20px 1.5rem 0;
  height: 47px;
  background: #282828;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.sidebar_active_btn__1gOVS {
  background-color: rgba(134, 219, 120, 1);
  border: none;
}

.sidebar_vocals_heading__128e_ {
  display: block;
  font-size: 1.2rem;
  margin: 0 !important;
}

.sidebar_vocal_content__3Tme1 .sidebar_vocal__3F7g- {
  width: 200px;
  color: white;
}

.sidebar_vocal_content__3Tme1 {
  margin: 1rem 0 0rem 0;
  display: flex;
  justify-content: space-around;
  color: white;
}

.sidebar_vocal_content__3Tme1 p {
  margin-top: 1rem;
  font-size: 0.7rem;
}

.sidebar_download_icon__1xKNI {
  margin-top: 18px;
}

@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf")
    format("truetype");
}

.sidebar_wrapper__X6LMs {
  padding: 0 2rem 2rem 2rem;
  font-family: "Roboto Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: fixed;
  background-color: rgb(51, 51, 51);
  width: 42%; */
}
/* 
@media (max-width: 1050px) {
  .wrapper {
    width: 75% !important;
  }
}
@media (max-width: 599px) {
  .wrapper {
    top: 0.9rem !important;
  }
}
@media (max-width: 500px) {
  .wrapper {
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .wrapper {
    top: 0.4rem !important;
  }
} */

.sidebar_top_bar__FW0Kl {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

.sidebar_top_bar__FW0Kl h4 {
  color: "white";
}

.sidebar_top_bar__FW0Kl .sidebar_close_icon__1dNvr {
  color: gray;
  margin-top: 30px;
  cursor: pointer;
}

.sidebar_wrapper__X6LMs .sidebar_absolute_close_icon__2I-Pe {
  display: block;
  color: gray;
  margin: 10px 10px 0px 0px;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  left: 100;
  right: 0;
  bottom: 100;
  top: 0;
}

.sidebar_email__1Bdvg,
.sidebar_name__2Ambv {
  margin: 0;
  margin-top: -70px;
  font-size: 0.9rem;
}

.sidebar_name__2Ambv {
  margin-top: 1.5rem !important;
}

.sidebar_stats_wrapper__2l22y {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  grid-row-gap: 0.1rem;
  row-gap: 0.1rem;
  justify-content: center;
}

.sidebar_stats_wrapper__2l22y span {
  font-size: 1.3rem;
  color: white;
  vertical-align: bottom;
}

.sidebar_stats_wrapper__2l22y p {
  vertical-align: middle;
  font-size: 0.8rem;
  display: inline;
  padding-left: 1rem;
}

.sidebar_button_group__2mSjQ {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.sidebar_btn__TFa8_,
.sidebar_active_btn__1OdAU {
  width: 178px;
  margin: 1.5rem 20px 1.5rem 0;
  height: 47px;
  background: #282828;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.sidebar_download_icon__3UGoe {
  margin-top: 8px;
}

.sidebar_vocal_input_container__2R9KS {
  margin: 3rem 0 1.5rem 0;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: center;
  width: 100%;
  height: 47px;
  background: #282828;
  border: 1px solid #ffffff;
  border-radius: 5px;
}

.sidebar_vocal_input_container__2R9KS form input {
  border: none;
  outline: none;
  color: white;
  width: 200%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;
  height: 40px;
}

@media (max-width: 1250px) and (min-width: 1050px) {
  .sidebar_vocal_input_container__2R9KS form input {
    width: 150%;
  }
}

.sidebar_vocal_input_container__2R9KS span {
  font-size: 1.5rem;
  margin-top: 7px;
}

.sidebar_vocal_results__--yS0 {
  margin-top: 1.5rem;
  display: flex;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  width: 100%;
}

.sidebar_valid_btn__wuKcS {
  align-self: center;
  background: #86db78;
  border: 1px solid #ffffff;
  border-radius: 5px;
  width: 1000px;
  height: 41px;
  color: white;
}

.sidebar_vocal_results__--yS0 p {
  display: inline;
  font-size: 0.8rem;
  align-self: center;
}

.sidebar_vocal_results__--yS0 span {
  display: inline;
  color: #86db78;
  font-size: 1rem;
  align-self: center;
}

.sidebar_save_btn__26Jq6 {
  margin: 1.5rem 0;
  width: 100%;
  height: 55px;
  background: #86db78;
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: white;
}

@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf")
    format("truetype");
}

.sidebar_wrapper__3P5AI {
  padding: 0 2rem 2rem 2rem;
  font-family: "Roboto Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* position: fixed;
  background-color: rgb(51, 51, 51);
  width: 42%; */
}

.sidebar_top_bar__2JC-z,
.sidebar_vocal_top_bar__RmAgL {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
}

.sidebar_top_bar__2JC-z h4 {
  color: "white";
}

.sidebar_top_bar__2JC-z .sidebar_close_icon__1G1FT {
  color: gray;
  margin-top: 30px;
  cursor: pointer;
}

.sidebar_email__3gc58 {
  margin: 0 0 1.5rem 0;
  font-size: 0.9rem;
}

.sidebar_pay_status_icon__2EnYw {
  color: #86db78 !important;
  font-size: 1.5rem;
  margin: 1rem 1rem 0 0;
  vertical-align: middle;
}
.sidebar_pay_status__g4u1H,
.sidebar_pay_date__3jCPa {
  margin: 1.5rem 1.5rem 0 0 !important;
  font-size: 0.9rem;
  display: inline;
}

.fr-container{
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-top: 10px;
    width: 60%;
    /* justify-content: start; */
    grid-gap: 8px;
    gap: 8px;
}

.fr-container .rf-referal{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
}

.fr-container .rf-email-status{
    display: flex;
    justify-content: space-between;
    width: 60%;
}



.rf-steps-container{
    display: flex;
    flex-wrap: wrap;
    border-top: 2px solid #3B3B3B ;
}

.rf-earned-container .total-earning{
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    border-top: 2px solid #3B3B3B ;
    border-bottom: 2px solid #3B3B3B ;
}





/* ReferalStep */
.referal-step-contianer{
    max-width: 250px;
    padding: 5px;
}
.referal-step-contianer .step-heading{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.step-heading .num-container{
    background: #000000;
    /* font-weight: bold; */
    border-radius: 10px;
    padding: 5px 10px;
    margin: 5px 0;
}
.referal-step-contianer .step-detail{
     color: #ffffffb1;
}


/* Referal Form */

.referal-form-container{
    /* background-color:#D9D9D9 ; */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    grid-gap: 10px;
    gap: 10px;
    padding: 10px;
}

.referal-form-container .referal-form-label{
    color: #000000;

}

.referal-link{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* padding: 8px; */
    /* background-color: #fff; */
    /* color: #00000075; */
    /* padding: 10px 0; */
    padding-left: 5px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #9F9F9F;
    height: 50px;

}

.referal-link .link-btn{
    min-height: 100%;
    text-align: center;
    border: 1px solid #57A4FF !important;
    background-color: #57a4ff45;
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    cursor: pointer;


}


.refer-email{
    /* background: #fff; */
    
    height: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #9F9F9F;
    height: 50px;
    

}

.refer-email input{
    
    font-size: 1.25rem;
    border: none;
    background-color: transparent;
    width: 100%;
    color: #fff;
    margin-left: 10px;
}

.refer-email input:focus{
    outline: none;
}

.refer-email .share-btn{
    min-height: 100%;
    background: #636363;
    color: #fff;
    font-size: 1rem;
    border: none;
    font-size: 1rem;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    padding: 0 15px;
    border: 1px solid #9F9F9F !important;
}

@media screen and (max-width:1000px) {
    .fr-container{
        /* background: #000; */
        min-width: 100%;
        margin-top: 20px;
        padding: 0 10px;
    }
    
}
.affiliate-container{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding-left: 30px;
    max-width: 50rem;
}

.affiliate-container .affiliate-heading{
    
}

.affiliate-btn .start-btn{
    background-color: #38BC22;
    color: #fff;
    border: 1px solid #333333;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    

}




.affiliate-container .affiliate-steps{
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #3B3B3B;
    padding-top: 20px;
}

.affiliate-steps .affiliate-arrow{
    display: flex;
    align-items: center;
}












/* affiliate step */

.affiliate-step-container{
    /* background: #000; */
    max-width: 18rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid white; */


}

.affiliate-step-container .affiliate-image{
    background-color: #fff;
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.affiliate-step-container .step-heading{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    /* color: aquamarine; */
}

.affiliate-step-container .step-detail{
    opacity: 0.7;
    text-align: center;
}
.dialogue_dialogueRow__1ONCN{
    background-color: #3D4756;
}

.dialogue_dialogueRow__1ONCN:hover{
    background-color: #57A4FF;
}

.dialogue_dialogueRowBackground__3xjyo{
    
    background-color: #3D4756;
}
.referrals-con{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.referrals{
    max-width: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.referrals .title{
    font-weight: bolder;
}
.referrals .title2{
    margin: 7px 0px
}
.referrals .title3{
    margin-bottom: 10px
}
.referrals .claim-btn{
    border: 1px solid #FFBF57;
    border-radius: 5px;
    padding: 10px 25px;
    background-color: #ffbf571c;
    color: #FFBF57;
    font-weight: 700;

}
.referrals .img-container{
    padding: 40px 80px;
    border: 2px solid #FFBF57;
    text-align: center;
    border-radius: 25px;
}
.referrals .img-container img{
    width: 100%;
    height: auto;
}
