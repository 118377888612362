@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Mono&display=swap");
/* @import url(https://db.onlinewebfonts.com/c/032d6b2c34344e22d2cbca6b7050d642?family=Eina01-SemiBold); */
/* @import url("https://db.onlinewebfonts.com/c/2d57f676e3d6955778fb8acac0176b9a?family=Eina01-Bold"); */

@font-face {
  font-family: "Eina01";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://warbls.s3.eu-north-1.amazonaws.com/public/Eina01-Regular.ttf") format("truetype");
}




body {
  margin: 0;
  font-family: "Eina01-SemiBold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#fff";
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

p,
h1,
h2,
h2,
h4,
h5,
h6 {
  font-family: "Eina01", "Roboto Mono", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #42de29;
  border-radius: 6px;
}

.error {
  color: #ff0000;
}

.m-0 {
  margin: 0px;
}

.pointer {
  cursor: pointer;
}

p {
  margin: 0px;
}

.apexcharts-toolbar {
  display: none !important;
}

.MuiTimelineItem-missingOppositeContent:before {
  flex: 0 !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
  flex-basis: 0% !important;
}

.MuiTimelineDot-defaultGrey {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid lightgray;
}

.MuiTimelineContent-root {
  margin-top: 10px !important;
}

.MuiToolbar-regular {
  min-height: 64px !important;
}

wave {
  overflow: hidden !important;
}

header {
  z-index: 2000 !important;
}

.MuiDrawer-paper {
  background-color: #333333 !important;
}

.truncate {
  text-overflow: ellipsis;

  /* Needed to make it work */
  overflow: hidden;
  white-space: nowrap;
}

.scroll-left {
  width: 100%;
  position: relative;
}
.scroll-left p {
  width: 100%;
  height: 100%;
  margin: 0;
  margin-top: 5px;
  text-align: center;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -moz-animation: scroll-left 10s linear infinite;
  -webkit-animation: scroll-left 10s linear infinite;
  animation: scroll-left 10s linear infinite;
}
/* Move it (define the animation) */
@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
  }
}
@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes scroll-left {
  0% {
    -moz-transform: translateX(100%); /* Browser bug fix */
    -webkit-transform: translateX(100%); /* Browser bug fix */
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%); /* Browser bug fix */
    -webkit-transform: translateX(-100%); /* Browser bug fix */
    transform: translateX(-100%);
  }
}

.composition {
  position: relative;
  width: 40%;
  top: -60%;
  margin-left: 200px;
}

@media (max-width: 1250px) {
  .composition {
    display: none;
  }
}

.composition__photo {
  width: 40%;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  outline-offset: 2rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo {
    float: left;
    position: relative;
    width: 33.33333333%;
    -webkit-box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
    box-shadow: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);
  }
}
.composition__photo--p1 {
  left: 25%;
  top: 9rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p1 {
    top: 0;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
.composition__photo--p2 {
  right: 0;
  top: 2rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p2 {
    top: -1rem;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    z-index: 100;
  }
}
.composition__photo--p3 {
  left: 0;
  top: 14rem;
}
@media only screen and (max-width: 56.25em) {
  .composition__photo--p3 {
    top: 1rem;
    left: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
.composition__photo:hover {
  outline: 1.5rem solid rgba(79, 141, 214, 0.6);
  -webkit-transform: scale(1.05) translateY(-0.5rem);
  transform: scale(1.05) translateY(-0.5rem);
  -webkit-box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.composition:hover .composition__photo:not(:hover) {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

option {
  background-color: #202020 !important;
}
#popover .MuiPaper-root {
  border: 2px solid red;
  left: 1051px !important;
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

.bpm-wrapper {
  position: relative;
}

.bpm-button,
.apply-button {
  background-color: transparent;
  width: 6rem;
  padding: 8px 2rem;
  display: flex;
  justify-content: center;
  color: white;
  text-align: center;
  border-radius: 5px;
  outline: none;
  border: 1px solid white;
  cursor: pointer;
}

.bpm-tooltip {
  display: none;
  background-color: rgb(77, 77, 77);
  position: absolute;
  left: -100px;
  padding: 20px 40px 20px;
  margin: 10px auto;
  width: 300px;
  z-index: 10000;
  border-radius: 6px;
}
.bpm-tooltip h6 {
  margin: 0 0 10px;
  color: white;
  text-align: center;
  font-size: 18px;
}

.active {
  display: unset !important;
}

.apply-button {
  border: 1px solid white;
  padding: 0.6rem 1rem;
  color: white;
  margin: 10px auto;
  float: right;
  cursor: pointer;
  outline: none;
  font-size: 15px;
}

.MuiSlider-root {
  width: 72% !important;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}

.scrollbar-container {
  max-width: 90vw;
  overflow: hidden;
  margin: 0 auto;
}

.table-row td{
  position: relative;
  z-index: 10;
}

.hr-text-container {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.hr-text {
  flex: 1;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0 5px;
}

.hr-text-span {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  padding: 0px 20px;
}

.inputEmailCheckout > input[name=email] {
  text-align: right;
}

@media only screen and (min-width: 1280px) {
  .table-row:hover::before{
    content: '';
    border-radius: 10px;
    position:absolute;
    inset: 0;
    background-color: #464646;
    z-index: 1;
  }
  #table-play-row::before{
    content: '';
    border-radius: 10px;
    position:absolute;
    inset: 0;
    background-color: #57A4FF66;
    z-index: 1;
  }
}

@media only screen and (max-width: 1280px) {
  .table-row:hover{
    background-color: #464646;
  }
}

* {
    padding: 0;
}

body { background-color: #292929; }

section {
    margin-top: -80px;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
}

ul#downArrow {
    position: relative;
    width: 40px;
    display: flex;
    align-items: center;
    flex-flow: column;
}

ul#downArrow li {
    list-style: none;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 100%;
    margin-bottom: 10px;
    animation: ani 0.8s infinite ease-in-out;
    animation-delay: calc(0.2s * var(--i));
}

ul#downArrow::before,
ul#downArrow::after {
    position: absolute;
    content: '';
    background-color: #fff;
    width: 30px;
    height: 3px;
    bottom: 0;
}

ul#downArrow::before {
    right: 0;
    transform: rotate(-45deg);
    transform-origin: center right;
}

ul#downArrow::after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: center left;
}

@keyframes ani {
    0% {
        opacity: 0;
        transform: scale(0.5) translateY(-5px);
    }

    50% {
        opacity: 1;
        transform: scale(1) translateY(20px);
    }

    100% {
        opacity: 0;
        transform: scale(0.5) translateY(40px);
    }

}


