.referrals-con{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}
.referrals{
    max-width: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.referrals .title{
    font-weight: bolder;
}
.referrals .title2{
    margin: 7px 0px
}
.referrals .title3{
    margin-bottom: 10px
}
.referrals .claim-btn{
    border: 1px solid #FFBF57;
    border-radius: 5px;
    padding: 10px 25px;
    background-color: #ffbf571c;
    color: #FFBF57;
    font-weight: 700;

}
.referrals .img-container{
    padding: 40px 80px;
    border: 2px solid #FFBF57;
    text-align: center;
    border-radius: 25px;
}
.referrals .img-container img{
    width: 100%;
    height: auto;
}